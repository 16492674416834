import React from "react";
import SuccessImage from "../../assets/success.gif";

export default function Alert({ handleClose }) {
    return (
        <div>

            <div className="main-modal">
                <div className="main-modal-content card col-md-5 col-lg-4 mx-auto">
                    <div className="card-body">
                        <div className="main-modal-body">
                            <div className="text-center">
                                <img src={SuccessImage} alt="loader" />
                                <h4>Thank you!</h4>
                                <h4>Form submitted Successfully</h4>
                                <button
                                    className="btn btn-blue px-4 py-3 mt-5"
                                    style={{ width: "47%" }}
                                    onClick={handleClose}
                                >
                                    Close
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}