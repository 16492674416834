import React from 'react'
import logo from '../../assets/logo.png'

export default function AboutRegistration() {
    return (

        // <div className=" text-lg-start text-center "style={{paddingTop:"90px"}}>
        //     <div className='ps-md-1 text-center text-lg-start'>
        //         <img
        //             src={logo}
        //             alt=""
        //             className=""
        //             style={{ marginBottom: "40px" }}
        //             width="290px"

        //         />{" "}
        //     </div>

        //     <h4
        //         className='mx-auto mx-lg-0'
        //         style={{

        //             background: '#0096a0',

        //             fontSize: "25px",

        //             color: 'white',

        //             padding: '.5em .4em',

        //             width: 'fit-content',

        //             // marginTop: "100px",

        //         }}

        //     >Apply to Become a Fellow</h4>


        //     <br />


        //     <h4 className="my-4">
        //         Are you interested in acquiring technical skills to become more competitive 
        //         in the local and global technical talent marketplace?

        //     </h4>

        //     <h5 className='my-4'>Apply to join the 3MTT fellowship.</h5>

        //     <a href="https://3mtt.nitda.gov.ng/" className='py-2 mt-4' target="_blank"
        //         style={{textDecoration:"none", color:"#0096a0", borderBottom:"1px solid #0096a0"}} rel="noreferrer"
        //     >
        //         About the 3MTT Programme
        //     </a>

        // </div>

        <div className='pt-5'>
            <img src={logo} alt="" className='' style={{marginBottom:"20px"}} width="270px" /> <br />
            {/* <small style={{backgroundColor:"#F9DCAD", fontSize:"25px",margin:"100px 0px"}} className='mt-5 py-2 px-2 rounded-2'>
                Tech Talent Fellow
            </small> <br /> */}
            {/* <small style={{backgroundColor:"#F9DCAD", fontSize:"15px",margin:"100px 0px"}} className='mt-5 py-2 px-2 rounded-2'>
                Introducing the 3 Million Technical Talent Program
            </small> */}
            <p className='my-4'>
                With partnership of US Consulate General Lagos, we're thrilled to announce the maiden 2023 
                cohort of our Polytechnic Students Entrepreneurship Program (PSEP) 2023. This program 
                presents a valuable opportunity for aspiring and student Entrepreneurs in all polytechnics 
                in Lagos State  build a sustainable business. The program spans 4 weeks, offering participants 
                comprehensive training, practical work experience, mentoring and business grants.
            </p>
            <p>
                If you're eager to embark on an exciting entrepreneurial journey, we extend a warm invitation 
                to apply for our Polytechnic Students Entrepreneurship Program. This program is thoughtfully 
                crafted to equip you with the essential training and experiences required to thrive in the 
                business realm and create a significant impact.
            </p>

            {/* <h5>Criteria to enroll</h5>

            <ol className='ps-md-0'>
                <li>
                    <p>At least a school leaving certificate</p>
                </li>
                <li>
                    <p>Must be between 18 to 35 years old</p>
                </li>
                <li>
                    <p>Must have access to an Internet enabled phone</p>
                </li>
                <li>
                    <p>Must have a valid reason for wanting to join</p>
                </li>
                <li>
                    <p>
                        Must have a valid means of Identification - NIN, Voter's card, 
                        driver's license or bank account details.
                    </p>
                </li>
            </ol> */}
        </div>
    )
}
