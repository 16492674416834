import { Toaster } from "react-hot-toast";
import Mainpage from "./components/mainpage";
import AboutRegistration from "./components/about";
import Footer from "./components/footer";

function App() {
  return (
    <div className="App">
        <div className="container-fluid m-0">
            <Toaster position="top-right" reverseOrder={false}/>
            <div className="row align-items-center">
                <div className=" col-lg-4 offset-lg-1">
                    <AboutRegistration/>
                </div>
                <div className="col-lg-7">
                    <Mainpage/>
                </div>
            </div>
            {/* <Footer/> */}
        </div>
    </div>
  );
}

export default App;
