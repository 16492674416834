import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import premblyLogo from '../../assets/premblyLogo.png'
import Alert from './Alert'
// import { localGov } from './lg'

export default function Mainpage() {
    const [page, setPage] = useState(1)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [gender, setGender] = useState("")
    const [age, setAge] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [school, setSchool] = useState("")
    const [busName, setBusName] = useState("")
    const [busProd, setBusProd] = useState("")
    const [busAge, setBusAge] = useState("")
    const [busCompetitors, setBusCompetitors] = useState("")
    const [busRegistration, setBusRegistration] = useState("")
    const [socialAvailability, setSocialAvailability] = useState("")
    const [commitment, setCommitment] = useState("")
    const [gradAvailability, setGradAvailability] = useState("")
    const [qualities, setQualities] = useState("")
    const [comments, setComments] = useState("")
    const [busLink, setBusLink] = useState("")
    const [doc_image, setDocImage] = useState(null)
    const [docFormat, setDocFormat] = useState("")



    const [technicalLevel, setTechnicalLevel] = useState("")
    const [course, setCourse] = useState("")
    const [computerAccess, setComputerAccess] = useState("")
    const [internetAccess, setInternetAccess] = useState("")
    const [reason, setReason] = useState("")
    const [verifMethod, setVerifMethod] = useState("")
    const [ninData, setNinData] = useState("")
    const [bvnData, setBvnData] = useState("")
    const [githubLink, setGithubLink] = useState("")
    const [loading, setLoading] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [consent, setConsent] = useState("")
    const [lgData, setLgData] = useState([])


    let next = () => {
        if (page === 1) {
            if (!firstName) {
                toast.error("Please Input Your First Name")
                return
            }
            if (!lastName) {
                toast.error("Please Input Your Last Name")
                return
            }
            if (!gender) {
                toast.error("Please Select Your Gender")
                return
            }
            if (!age) {
                toast.error("Select Your Age Range")
                return
            }
            setPage(2)
        }

        if (page === 2) {
            let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

            // let phone_reg = /(^+)(234){1}[0–9]{10})|((^234)[0–9]{10})|((^0)(7|8|9){1}(0|1){1}[0–9]{8})$/
            let phone_reg = /^(090|091|070|080|081)\d{8}$/

            if (!email) {
                toast.error("Please Input Your Email")
                return
            }

            if (email && (email_reg.test(email) === false)) {
                toast.error("Email Address is Invalid")
                return
            }

            if (!phone) {
                toast.error("Please Input Your Phone Number")
                return
            }
            if (phone && !phone_reg?.test(phone)) {
                toast.error("Please Input a Valid Nigerian Number")
                return
            }
            if (!school) {
                toast.error("Please select your school")
                return
            }
            setPage(3)
        }

        if (page === 3) {
            if (!busName) {
                toast.error("Please Input Your Business Name")
                return
            }
            if (!busProd) {
                toast.error("Please input your business product or services")
                return
            }
            if (!busAge) {
                toast.error("Please select how long you've been in business")
                return
            }
            if (!busCompetitors) {
                toast.error("Please enter your competitors")
                return
            }
            if (!busRegistration) {
                toast.error("Please confirm if your business has been registered")
                return
            }
            if (!socialAvailability) {
                toast.error("Please confirm if your business has a social media account")
                return
            }
            if ((socialAvailability === "Yes") && !busLink) {
                toast.error("Please enter your business social media link")
                return
            }
            setPage(4)
        }

        if (page === 4) {
            if (!reason) {
                toast.error("Please input why you are in the entrepreneurial space")
                return
            }
            if (!commitment) {
                toast.error("Please select if you will be available for 4 weeks of the program")
                return
            }
            if (!gradAvailability) {
                toast.error("Please select your availability to attend graduation ceremony")
                return
            }
            if (!qualities) {
                toast.error("Please input what qualities smakes you a strong candidate to be selected by a US Funded Program")
                return
            }
            setPage(5)
        }

        if (page === 5) {
            if (!doc_image) {
                toast.error("Please upload your Student ID Card")
                return
            }
            if ((doc_image?.type  !== "image/png") && (doc_image?.type  !== "image/jpeg") && (doc_image?.type !== "image/jpg")) {
                toast.error("Please make sure you are uploading the right image formats")
                return
            }
            if (doc_image.size > 1100000) {
                toast.error("Please make sure your ID Card size is not bigger than 1mb")
                return
            }
            if (!consent) {
                toast.error("Kindly agree to the terms and conditions")
                return
            }
            pushAllData()
        }

    }

    let prev = () => {
        if (page === 2) {
            setPage(1)
        }
        if (page === 3) {
            setPage(2)
        }
        if (page === 4) {
            setPage(3)
        }
        if (page === 5) {
            setPage(4)
        }

    }

    let pushAllData = () => {
        setLoading(true)

        var raw = JSON.stringify({
            fullName: firstName + " " + lastName,
            sex: gender,
            age: age,
            email: email,
            mobile_number: phone,
            phone_number: phone,
            reason: reason,
            program_availability: commitment,
            graduation_availability: gradAvailability,
            school:school,

            business_name: busName,
            product: busProd,
            business_duration: busAge,
            social_media_presence: socialAvailability,
            business_link: busLink || "null",
            is_business_registered: busRegistration,
            current_competitors: busCompetitors,
            comments: comments,
            qualities: qualities,
            certificate : docFormat?.replaceAll("data:image/png;base64,","").replaceAll("data:image/jpg;base64","").replaceAll("data:image/jpeg;base64","")


        });

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: raw,
            redirect: 'follow'
        };


        fetch("https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/identity-reg-en-prod/event/student-reg", requestOptions)

        .then(response => response?.json())
        .then((result) => {
            setLoading(false)
            if (result?.Success) {
                setSuccessModal(true)
            }
            else {
                toast.error(result?.message)
            }
        }).catch(error => {
            setLoading(false)
            if (!error?.Success) {
                toast.error(error?.message)
            }
        })

    };


    const handleClose = () => {
        setSuccessModal(false)
        clearData()
        setPage(1)
        // window.location.href = 'https://3mtt.nitda.gov.ng/'
    }

    let clearData = () => {
        setFirstName("") 
        setLastName("")
        setGender("")
        setAge("")
        setEmail("")
        setPhone("")
        setReason("")
        setCommitment("")
        setGradAvailability("")
        setSchool("")

        setBusName("")
        setBusProd("")
        setBusAge("")
        setSocialAvailability("")
        setBusLink("")
        setBusRegistration("")
        setBusCompetitors("")
        setComments("")
        setQualities("")
        setDocFormat("")


        // setFirstName("")
        // setLastName("")
        // setVerifMethod("")
        // setNinData("")
        // setBvnData("")
        // setEmail("")
        // setGender("")
        // setAge("")
        // setPhone("")
        // setLocation("")
        // setAddress("")
        // setOrigin("")
        // setEducationalLevel("")
        // setTechnicalLevel("")
        // setEmploymentStatus("")
        // setCourse("")
        // setComputerAccess("")
        // setInternetAccess("")
        // setCommitment("")
        // setGithubLink("")
        // setReason("")
    }

    // let getLG = (key) => {
    //     localGov?.forEach((val) => {
    //         if ((val?.state === key)) {
    //             setLgData(val?.lgas)
    //         }
    //     })
    // }

    let getBase64 = (e) => {
        setDocImage(e.target.files[0])
        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setDocFormat(reader.result)
        };
    }


    return (
        <div className='main-form'>
            {successModal && <Alert handleClose={handleClose} />}
            <div className="py-5">
                <div className="card">
                    <div className="card-body px-lg-3 px-xl-5">
                        <div className="row align-items-center">
                            <div className="col-md-4 my-md-5">
                                <h5 className='mt-md-0'>Enrollment Process</h5>

                                <div className="d-flex process-tag">
                                    <p style={{ border: page > 1 ? '1px solid #0096a0' : '' }}>{page > 1 ? <span style={{ color: '#0096a0', fontSize: "13px" }}>&#x2713;</span> : 1}</p>
                                    <div className="desc">
                                        <h6>Bio Data</h6>
                                        <small>Please enter your basic information</small>
                                    </div>
                                </div>

                                <div className="d-flex process-tag">
                                    <p style={{ border: page > 2 ? '1px solid #0096a0' : '' }}>{page > 2 ? <span style={{ color: '#0096a0', fontSize: "13px" }}>&#x2713;</span> : 2}</p>
                                    <div className="desc">
                                        <h6>Contact Information</h6>
                                        <small>Kindly enter your contact information</small>
                                    </div>
                                </div>

                                <div className="d-flex process-tag">
                                    <p style={{ border: page > 3 ? '1px solid #0096a0' : '' }}>{page > 3 ? <span style={{ color: '#0096a0', fontSize: "13px" }}>&#x2713;</span> : 3}</p>
                                    <div className="desc">
                                        <h6>Business Information</h6>
                                        <small>Please provide your business information to know more about your business</small>
                                    </div>
                                </div>

                                <div className="d-flex process-tag">
                                    <p style={{ border: page > 4 ? '1px solid #0096a0' : '' }}>{page > 4 ? <span style={{ color: '#0096a0', fontSize: "13px" }}>&#x2713;</span> : 4}</p>
                                    <div className="desc">
                                        <h6>Program Information</h6>
                                        <small>Program information provides a brief overview of the program's purpose</small>
                                    </div>
                                </div>

                                <div className="d-flex process-tag">
                                    <p style={{ border: page > 5 ? '1px solid #0096a0' : '' }}>{page > 5 ? <span style={{ color: '#0096a0', fontSize: "13px" }}>&#x2713;</span> : 5}</p>
                                    <div className="desc">
                                        <h6>Identification</h6>
                                        <small>Kindly provide your means of identification to verify your information/details</small>
                                    </div>
                                </div>


                            </div>

                            <div className="col-md-8 border-start">

                                <div className="col-md-10 mx-auto">
                                    <div className='form-div' >
                                        {(page === 1) &&
                                            <>
                                                <div className='mt-5'>
                                                    <fieldset className="form-control">
                                                        <legend>First Name</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <input type="text" className='w-100' value={firstName}
                                                                onChange={(e) => { setFirstName(e.target.value) }}
                                                                placeholder='Your answer'
                                                            />
                                                        </span>
                                                    </fieldset>
                                                </div>
                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>Last Name</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <input type="text" className='w-100' value={lastName}
                                                                onChange={(e) => { setLastName(e.target.value) }}
                                                                placeholder='Your answer'
                                                            />
                                                        </span>
                                                    </fieldset>
                                                </div>
                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>Select Gender</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <select className='w-100' value={gender} onChange={(gender) => setGender(gender.target.value)}>
                                                                <option value="">Select Gender </option>
                                                                <option value="male">Male </option>
                                                                <option value="Female">Female </option>
                                                            </select>
                                                        </span>
                                                    </fieldset>
                                                </div>

                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>Age Range</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <select className='w-100' value={age} onChange={(age) => setAge(age.target.value)}>
                                                                <option value="">Select age range </option>
                                                                <option value="20 years and below">20 years and below</option>
                                                                <option value="21 - 30 years">21 - 30 years </option>
                                                                <option value="31 - 35 years">31 - 35 years </option>
                                                                <option value="36 years and above">36 years and above </option>
                                                            </select>
                                                        </span>
                                                    </fieldset>
                                                </div>

                                            </>
                                        }
                                        {(page === 2) &&
                                            <>
                                                <div className='mt-5'>
                                                    <fieldset className="form-control">
                                                        <legend>Email</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <input type='email' className='w-100' value={email}
                                                                onChange={(e) => { setEmail(e.target.value) }}
                                                                placeholder='name@email.com'
                                                            />
                                                        </span>
                                                    </fieldset>
                                                </div>

                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>Phone number</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <input type='email' className='w-100' value={phone}
                                                                onChange={(e) => { setPhone(e.target.value) }}
                                                                placeholder='08012345678'
                                                            />
                                                        </span>
                                                    </fieldset>
                                                </div>

                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>Select your school</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <select className='w-100' value={school} onChange={(e) => {
                                                                setSchool(e.target.value)
                                                            }}>
                                                                <option value="">Select your school </option>
                                                                <option value="Yaba college of Technology">
                                                                    Yaba college of Technology
                                                                </option>
                                                                <option value="Lagos State College of Science and Technology">
                                                                    Lagos State College of Science and Technology
                                                                </option>
                                                                <option value="Wolex Polytechnic">Wolex Polytechnic</option>
                                                            </select>
                                                        </span>
                                                    </fieldset>
                                                </div>

                                            </>
                                        }
                                        {(page === 3) &&
                                            <>
                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>What is your Business Name ?</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <input type='text' className='w-100' value={busName}
                                                                onChange={(e) => { setBusName(e.target.value) }}
                                                                placeholder='Your answer'
                                                            />
                                                        </span>
                                                    </fieldset>
                                                </div>


                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>List your products or business services offer</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <textarea className="w-100" rows="3" value={busProd}
                                                                onChange={(e) => { setBusProd(e.target.value) }}
                                                                placeholder='Your answer'
                                                            ></textarea>
                                                        </span>
                                                    </fieldset>
                                                </div>

                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>How long have you been in business ?</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <select className='w-100' value={busAge} onChange={(e) => {
                                                                setBusAge(e.target.value)
                                                            }}>
                                                                <option value="">Select how long your business has been existing </option>
                                                                <option value="0-1 years">0-1 years</option>
                                                                <option value="1-3 years">1-3 years</option>
                                                                <option value="3-5 years">3-5 years </option>
                                                            </select>
                                                        </span>
                                                    </fieldset>
                                                </div>

                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>Who are your current competitors </legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <textarea className="w-100" rows="3" value={busCompetitors}
                                                                onChange={(e) => { setBusCompetitors(e.target.value) }}
                                                                placeholder='Your answer'
                                                            ></textarea>
                                                        </span>
                                                    </fieldset>
                                                </div>

                                                <div className='mt-4'>
                                                    <label htmlFor="busRegistration"><h6>Is your business registered ?</h6></label>
                                                    <div className="d-flex">
                                                        <>
                                                            <div className="input-group align-items-center">
                                                                <input type="radio" name="busRegistration" value="Yes" checked={busRegistration === "Yes"? true : false} 
                                                                    onChange={(e) => setBusRegistration(e.target.value)} 
                                                                />
                                                                <p className='mt-3 ms-2'>Yes</p>
                                                            </div>
                                                            <div className="input-group align-items-center">
                                                                <input type="radio" name="busRegistration" value="No" checked={busRegistration === "No"? true : false} 
                                                                    onChange={(e) => setBusRegistration(e.target.value)} 
                                                                />
                                                                <p className='mt-3 ms-2'>No</p>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>

                                                <div className='mt-4'>
                                                    <label htmlFor="socialAvailability"><h6>Is your business on social media?</h6></label>
                                                    <div className="d-flex">
                                                        <>
                                                            <div className="input-group align-items-center">
                                                                <input type="radio" name="socialAvailability" value="Yes"checked={socialAvailability === "Yes"? true : false} 
                                                                    onChange={(e) => setSocialAvailability(e.target.value)} 
                                                                />
                                                                <p className='mt-3 ms-2'>Yes</p>
                                                            </div>
                                                            <div className="input-group align-items-center">
                                                                <input type="radio" name="socialAvailability" value="No" checked={socialAvailability === "No"? true : false}  
                                                                    onChange={(e) => setSocialAvailability(e.target.value)} 
                                                                />
                                                                <p className='mt-3 ms-2'>No</p>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>

                                                {socialAvailability === "Yes" &&
                                                    <div className='mt-4'>
                                                        <fieldset className="form-control">
                                                            <legend>Kindly drop the link of your business</legend>
                                                            <span className="d-flex align-items-center ">
                                                                <i className="ri-mail-line" />
                                                                <textarea className="w-100" rows="3" value={busLink}
                                                                    onChange={(e) => { setBusLink(e.target.value) }}
                                                                    placeholder='Your answer'
                                                                ></textarea>
                                                            </span>
                                                        </fieldset>
                                                    </div>
                                                }

                                            </>
                                        }

                                        {(page === 4) &&
                                            <>
                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>Why are you in the entrepreneurial space? What fascinates you about entrepreneurship?</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <input type="text" className='w-100' value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </span>
                                                    </fieldset>
                                                </div>
                                                <div className='mt-4'>
                                                    <label htmlFor="commitment">
                                                        <h6>As this program is conducted virtually and offers a flexible schedule are you able to commit to 4 weeks duration virtual training</h6>
                                                    </label>
                                                    <div className="d-flex">
                                                        <>
                                                            <div className="input-group align-items-center">
                                                                <input type="radio" name="commitment" value="Yes" checked={commitment === "Yes"? true : false} 
                                                                    onChange={(e) => setCommitment(e.target.value)} 
                                                                />
                                                                <p className='mt-3 ms-2'>Yes</p>
                                                            </div>
                                                            <div className="input-group align-items-center">
                                                                <input type="radio" name="commitment" value="No" checked={commitment === "No"? true : false} 
                                                                    onChange={(e) => setCommitment(e.target.value)} 
                                                                />
                                                                <p className='mt-3 ms-2'>No</p>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    <label htmlFor="gradAvailability">
                                                        <h6>Graduation and award of grants will hold in Lagos, would you available to attend?</h6>
                                                    </label>
                                                    <div className="d-flex">
                                                        <>
                                                            <div className="input-group align-items-center">
                                                                <input type="radio" name="gradAvailability" value="Yes" checked={gradAvailability === "Yes"? true : false} 
                                                                    onChange={(e) => setGradAvailability(e.target.value)} 
                                                                />
                                                                <p className='mt-3 ms-2'>Yes</p>
                                                            </div>
                                                            <div className="input-group align-items-center">
                                                                <input type="radio" name="gradAvailability" value="No" checked={gradAvailability === "No" ? true : false} 
                                                                    onChange={(e) => setGradAvailability(e.target.value)} 
                                                                />
                                                                <p className='mt-3 ms-2'>No</p>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>What qualities do you feel sets you apart from other applications and make you a strong candidate to be selected by a US Funded Program?</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <input type="text" className='w-100' value={qualities} onChange={(e) => setQualities(e.target.value)} />
                                                        </span>
                                                    </fieldset>
                                                </div>
                                                <div className='mt-4'>
                                                    <fieldset className="form-control">
                                                        <legend>Comments</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            <input type="text" className='w-100' value={comments} onChange={(e) => setComments(e.target.value)} />
                                                        </span>
                                                    </fieldset>
                                                </div>

                                            </>
                                        }

                                        {(page === 5) &&
                                            <>
                                                <div className='mt-5'>
                                                    <fieldset className="form-control">
                                                        <legend>Upload your student ID Card</legend>
                                                        <span className="d-flex align-items-center ">
                                                            <i className="ri-mail-line" />
                                                            {!doc_image &&
                                                                <div className="batch-upload-div">
                                                                    <div className="">
                                                                        <i className="ri-image-2-line ri-xl pt-3" />
                                                                        <input type="file" name="" id=""
                                                                            className="batch-upload-btn"
                                                                            file={doc_image}
                                                                            onChange={getBase64}
                                                                            accept=".jpeg,.jpg,.png"
                                                                            draggable={false}
                                                                        />
                                                                        <p className='p-0 m-0' style={{font:"12px S-regular"}}>ID Card Size should not be more than 1mb</p>
                                                                        <p className='p-0 ' style={{font:"12px S-regular"}}>.jpeg, .jpg, .png</p>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {doc_image &&
                                                                <div className="row align-items-center">
                                                                    <div className="col-md-8 d-flex align-items-center">
                                                                        <i className="ri-image-2-line ri-xl me-2" />
                                                                        <span>
                                                                            <p className="p-0 m-0" style={{ fontSize: "15px" }}>{doc_image?.name}</p>
                                                                            <p className="p-0 m-0" style={{ fontSize: "13px", color: "#8D8D8D" }}>
                                                                                Size: {doc_image.size / 1000} KB
                                                                            </p>
                                                                        </span>

                                                                    </div>
                                                                    {/* {! && */}
                                                                        <div className="col-md-4 d-flex justify-content-md-end">
                                                                            <span>
                                                                                <button className="btn btn-danger"
                                                                                    onClick={() => {setDocImage(null); setDocFormat("")}}
                                                                                    style={{ border: "None", borderRadius: "5px" }}
                                                                                >
                                                                                    <i className="ri-delete-bin-3-line ri-xl" />
                                                                                    Remove
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    {/* } */}
                                                                </div>
                                                            }
                                                        </span>
                                                    </fieldset>
                                                </div>

                                                <div className="mt-4">
                                                    <label htmlFor="consent">
                                                        <h6>
                                                            By submitting this application, you agree to abide by the terms
                                                            and conditions of the program, including attendance, code of conduct,
                                                            and other program-specific requirements.
                                                        </h6>
                                                    </label>
                                                    <div className="input-group align-items-center">
                                                        <input type="radio" name="consent" value="Yes" checked={consent === "Yes"? true : false} 
                                                            onChange={(e) => setConsent(e.target.value)} 
                                                        />
                                                        <p className='mt-3 ms-2'>Yes, I agree</p>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="mb-3 text-center">
                                            <div className="d-flex mb-5 justify-content-between">
                                                <button className='btn btn-blue-outline py-3 mt-5' style={{ width: "47%" }} onClick={prev} disabled={((page < 2) || (loading)) ? true : false}> Previous </button>
                                                <button className='btn btn-blue px-4 py-3 mt-5' style={{ width: "47%" }} onClick={next} disabled={(loading) ? true : false}>
                                                    {page === 5 ?
                                                        <>
                                                            {loading ?
                                                                <>
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                                </>
                                                                :
                                                                <>
                                                                    Submit
                                                                </>
                                                            }
                                                        </>
                                                        : "Next"
                                                    }
                                                </button>
                                            </div>
                                            <p>
                                                Powered by  <img src={premblyLogo} style={{ width: "80px" }} alt="" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}
